import { Component, model } from '@angular/core';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import { PfActionButtonComponent } from 'pf-ui';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { CommonModule } from '@angular/common';
import { QuestionPreviewComponent } from '@app/components/questions/question-preview/question-preview.component';
import { SpecificClosableDialogComponent } from '../specific-closable-dialog/specific-closable-dialog.component';

@Component({
  selector: 'app-view-question',
  standalone: true,
  imports: [
    CommonModule,
    PfActionButtonComponent,
    QuestionPreviewComponent,
    SpecificClosableDialogComponent,
  ],
  templateUrl: './view-question.component.html',
  styleUrl: './view-question.component.scss',
})
export class ViewQuestionComponent {
  question = model<QuestionDetail>();

  isTest = false;
  showCorrection = false;

  constructor(
    private config: DynamicDialogConfig<{
      question?: QuestionDetail;
      isTest?: boolean;
      showCorrection?: boolean;
    }>,
    private dialog: DynamicDialogRef,
  ) {
    this.isTest = this.config.data?.isTest === true;
    this.showCorrection = this.config.data?.showCorrection === true;

    this.question.update(() => this.config.data?.question);
  }

  public close(isTest: boolean): void {
    this.dialog.close(isTest);
  }
}
