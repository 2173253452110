<app-specific-closable-dialog
  [title]="isTest ? 'Tester la question' : 'Visualiser la question'"
  (closeClicked)="close(isTest)"
  [showCloseButton]="true"
>
  <p *ngIf="isTest" class="mb-3 mt-0">
    Simuler une question d’examen théorique de code de la route en tant que
    candidat.
  </p>
  <app-question-preview
    imageContainerHeight="25vh"
    [question]="question()"
    [isButtonDisabled]="!isTest"
    [isTrainingCorrection]="showCorrection"
  ></app-question-preview>
</app-specific-closable-dialog>
